import React, { useEffect, useState } from "react";
import { message, Modal, Table, Button, Tag, Row, Col } from "antd";
import AppLayout from "../../layout/Layout";
import { getAllProductRequest } from "../AxiosApis/api";
import { confirmProductRequest } from "../AxiosApis/api";
import { EditOutlined } from "@ant-design/icons";

const Requests = () => {
  const [productRequests, setProductRequests] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const fetchProductRequests = async () => {
    try {
      const response = await getAllProductRequest();

      const filteredRequests = (response.data.data || [])
        .filter((request) => request.status !== "product_delivered") // Exclude delivered requests
        .sort((a, b) => new Date(b.requestTime) - new Date(a.requestTime)); // Sort by requestTime descending

      setProductRequests(filteredRequests);
    } catch (error) {
      message.error("Failed to load product requests");
    }
  };

  useEffect(() => {
    fetchProductRequests();
  }, []);

  const handleEditClick = (request) => {
    setSelectedRequest(request);
    setIsModalVisible(true);
  };

  const handleConfirmDelivery = async () => {
    if (!selectedRequest) return;

    try {
      const response = await confirmProductRequest({
        requestId: selectedRequest._id,
      });

      if (response.status === 200) {
        message.success(response.data.message);
        setIsModalVisible(false);
        fetchProductRequests(); // Refresh the list after confirmation
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        message.error(
          error.response.data.message || "Failed to confirm delivery"
        );
        console.error("Error response:", error.response.data);
      } else {
        message.error("Failed to confirm delivery");
        console.error("Error:", error);
      }
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Request Person",
      dataIndex: "userId",
      key: "userId",
      render: (user) => user?.name || "N/A",
    },
    {
      title: "Under SPF",
      dataIndex: "admin",
      key: "admin",
      render: (admin) => (
        <>
          <div>SPF Name: {admin?.name || "N/A"}</div>
          <div>SPF Email: {admin?.email || "N/A"}</div>
        </>
      ),
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      render: (products) =>
        products?.map((product) => (
          <div key={product.productId?._id || product._id}>
            {product.productId?.name || "Unknown Product"} - {product.quantity}
          </div>
        )) || "No products available",
    },
    {
      title: "Product Cost",
      dataIndex: "productCost",
      key: "productCost",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "product_delivered" ? "green" : "volcano"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => handleEditClick(record)}
        />
      ),
    },
  ];

  return (
    <AppLayout>
      <Table
        columns={columns}
        dataSource={productRequests}
        scroll={{ x: "max-content" }}
      />
      <Modal
        title="Product Request"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedRequest && (
          <div>
            <h3>Order ID: {selectedRequest.orderId}</h3>
            <p>
              Status:{" "}
              <Tag
                color={
                  selectedRequest.status === "product_delivered"
                    ? "green"
                    : "volcano"
                }
              >
                {selectedRequest.status}
              </Tag>
            </p>
            <h4>Products:</h4>
            {selectedRequest.products.map((product) => (
              <div key={product.productId?._id || product._id}>
                {product.productId?.name || "Unknown Product"} -{" "}
                {product.quantity}
              </div>
            ))}
            <Row>
              <Col span={24} style={{ textAlign: "end" }}>
                <Button type="primary" onClick={handleConfirmDelivery}>
                  Confirm Delivery
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </AppLayout>
  );
};

export default Requests;
