import React, { useContext, useState, useEffect } from "react";
import AppLayout from "../../layout/Layout";
import {
  Col,
  Row,
  Space,
  Table,
  Tag,
  Modal,
  Button,
  notification,
  Input,
} from "antd";
import { UserContext } from "../../UserContext";
import { EditOutlined } from "@ant-design/icons";
import {
  cancelAssignDelivery,
  getDeliveryOrders,
  markAsDelivered,
} from "./../AxiosApis/api";

const { TextArea } = Input;

const Delivery = () => {
  const { userDetails, loading: userLoading } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      if (!userDetails || userDetails?.role !== "transport") return;

      setLoadingOrders(true);
      try {
        const response = await getDeliveryOrders(userDetails._id);
        const filteredOrders = (response.data.data.orders || []).filter(
          (order) => order.status !== "delivered"
        );

        // Sort orders by deliveryAssignedTime in descending order
        const sortedOrders = filteredOrders.sort(
          (a, b) =>
            new Date(b.deliveryAssignedTime) - new Date(a.deliveryAssignedTime)
        );

        setOrders(sortedOrders);
      } catch (error) {
        console.error("Error fetching delivery orders:", error);
      } finally {
        setLoadingOrders(false);
      }
    };

    if (!userLoading && userDetails) {
      fetchOrders();
    }
  }, [userDetails, userLoading]);

  // Handle Edit click to show modal
  const handleEditClick = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
    setRejectionReason(""); // Reset rejection reason
  };

  // Close modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
    setRejectionReason(""); // Reset rejection reason
  };

  const handleRejectAssignment = async () => {
    if (!rejectionReason) {
      notification.warning({
        message: "Warning",
        description: "Please provide a reason for rejecting the assignment.",
      });
      return;
    }

    setLoadingAction(true);

    try {
      await cancelAssignDelivery({
        orderId: selectedOrder.orderId,
        transportPersonId: userDetails._id,
        reason: rejectionReason, // Include the rejection reason
      });
      const updatedOrders = orders.map((order) =>
        order.orderId === selectedOrder.orderId
          ? { ...order, status: "resolved", deliveryPerson: null }
          : order
      );
      notification.success({
        message: "Success",
        description: "Delivery assignment canceled successfully",
      });
      setOrders(updatedOrders);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error canceling Delivery assignment:", error);
      notification.error({
        message: "Error",
        description: "There was an error canceling the Delivery assignment",
      });
    } finally {
      setLoadingAction(false);
    }
  };

  // Handle "Delivered" action
  const handleDelivered = async () => {
    setLoadingAction(true);
    try {
      const response = await markAsDelivered({
        transportPersonId: userDetails._id,
        orderId: selectedOrder.orderId,
      });
      if (response.status === 200) {
        const updatedOrders = orders.map((order) =>
          order.orderId === selectedOrder.orderId
            ? { ...order, status: "delivered", deliveredTime: new Date() }
            : order
        );
        notification.success({
          message: "Success",
          description: "Order Delivered Successfully",
        });
        setOrders(updatedOrders);
        setSelectedOrder({ ...selectedOrder, status: "delivered" });
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error marking as delivered:", error);
    } finally {
      setLoadingAction(false);
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Product Model",
      dataIndex: "productModel",
      key: "productModel",
    },
    {
      title: "Issue",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Serial Number",
      dataIndex: "serialno",
      key: "serialno",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "assign_delivery" ? "blue" : "green"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => `${address?.fulladdress}`,
    },
    {
      title: "Assigned Time",
      dataIndex: "deliveryAssignedTime",
      key: "deliveryAssignedTime",
      render: (time) => (time ? new Date(time).toLocaleString() : "N/A"),
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space>
          <EditOutlined onClick={() => handleEditClick(record)} />
        </Space>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row gutter={(8, 16)}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={orders}
            loading={loadingOrders || userLoading}
            rowKey="_id"
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>

      <Modal
        title={`Order ID: ${selectedOrder?.orderId}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            onClick={handleRejectAssignment}
            disabled={selectedOrder?.status === "delivered"}
          >
            Reject Assignment
          </Button>,
          <Button
            key="delivered"
            type="primary"
            loading={loadingAction}
            onClick={handleDelivered}
            disabled={selectedOrder?.status !== "assign_delivery"}
          >
            Mark as Delivered
          </Button>,
        ]}
      >
        <p>Customer Name: {selectedOrder?.name}</p>
        <p>Call Number: {selectedOrder?.email}</p>
        <p>Phone Number: {selectedOrder?.phoneNumber}</p>
        <p>Address: {selectedOrder?.address?.fulladdress}</p>
        <p>Brand: {selectedOrder?.productName}</p>
        <p>Product Model: {selectedOrder?.productModel}</p>
        <p>Serial Number: {selectedOrder?.serialno}</p>
        <p>Size: {selectedOrder?.serialnosize}</p>
        <p>Issue: {selectedOrder?.description}</p>
        <p>Status: {selectedOrder?.status}</p>
        <p>Product Name: {selectedOrder?.productName}</p>
        <p>
          Assigned Time:{" "}
          {selectedOrder?.deliveryAssignedTime
            ? new Date(selectedOrder.deliveryAssignedTime).toLocaleString()
            : "N/A"}
        </p>
        <p>
          Current Status:{" "}
          <Tag
            color={
              selectedOrder?.status === "assign_delivery" ? "blue" : "green"
            }
          >
            {selectedOrder?.status}
          </Tag>
        </p>
        <TextArea
          rows={4}
          placeholder="Provide a reason for rejecting the assignment"
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
      </Modal>
    </AppLayout>
  );
};

export default Delivery;
