import React, { useContext, useState } from "react";
import {
  Layout,
  Menu,
  Avatar,
  Typography,
  Row,
  Col,
  Dropdown,
  message,
  Drawer,
  Button,
} from "antd";
import {
  MenuOutlined,
  CarOutlined,
  DatabaseOutlined,
  ExportOutlined,
  InboxOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ThunderboltOutlined,
  BankOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./../UserContext";
import { Grid } from "antd";
import { logout } from "../components/AxiosApis/api";

const { Header, Sider, Content } = Layout;
const { useBreakpoint } = Grid;

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const { userDetails, loading, setUserDetails } = useContext(UserContext);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const screens = useBreakpoint();
  if (loading) {
    return <div>Loading...</div>;
  }

  const handleOrders = () => {
    navigate("/orders");
  };

  const handleSuperOrders = () => {
    navigate("/Allorders");
  };

  const handleStaff = () => {
    navigate("/staff");
  };

  const handleSuperStaff = () => {
    navigate("/AllStaff");
  };

  const handleEngineer = () => {
    navigate("/engineer");
  };

  const handleStoreRequest = () => {
    navigate("/storerequest");
  };

  const handleDelivery = () => {
    navigate("/delivery");
  };

  const handleInventory = () => {
    navigate("/inventory");
  };

  const handleConfirmRequest = () => {
    navigate("/confirmRequest");
  };

  const handleRequests = () => {
    navigate("/requests");
  };

  const handlePickUp = () => {
    navigate("/pickup");
  };

  const handleProductList = () => {
    navigate("/brands");
  };

  const handleVendorList = () => {
    navigate("/vendors");
  };

  const handleSeriveRequests = () => {
    navigate("/serviceRequests");
  };

  const handleLogout = async () => {
    try {
      await logout(); // Perform the logout API call
      localStorage.removeItem("token"); // Clear the token first
      message.success("Logged out successfully!");

      navigate("/");

      // Delay resetting user details to avoid conflict during navigation
      setTimeout(() => {
        setUserDetails(null); // Clear the user details after navigating
      }, 1000); // Adjust delay as needed
    } catch (error) {
      message.error("Logout failed. Please try again.");
    }
  };

  const renderMenuItems = () => {
    if (!userDetails) return null;

    const items = [];
    switch (userDetails?.role) {
      case "superadmin":
        items.push(
          <>
            <Menu.Item
              key="9"
              onClick={handleSuperOrders}
              icon={<DatabaseOutlined />}
            >
              Orders
            </Menu.Item>
            <Menu.Item
              key="10"
              onClick={handleSuperStaff}
              icon={<UsergroupAddOutlined />}
            >
              SPF & SPM
            </Menu.Item>
            <Menu.Item
              key="6"
              onClick={handleInventory}
              icon={<BankOutlined />}
            >
              Inventory
            </Menu.Item>
            <Menu.Item
              key="8"
              onClick={handleRequests}
              icon={<InboxOutlined />}
            >
              Store Requests
            </Menu.Item>
            <Menu.Item
              key="12"
              onClick={handleConfirmRequest}
              icon={<ExportOutlined />}
            >
              Confirm Requests
            </Menu.Item>
            <Menu.Item
              key="15"
              onClick={handleSeriveRequests}
              icon={<BellOutlined />}
            >
              Service Requests
            </Menu.Item>
          </>
        );
        break;

      case "admin":
        items.push(
          <>
            <Menu.Item
              key="1"
              onClick={handleOrders}
              icon={<DatabaseOutlined />}
            >
              Orders
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={handleStaff}
              icon={<UsergroupAddOutlined />}
            >
              Staff
            </Menu.Item>
            <Menu.Item
              key="7"
              onClick={handleProductList}
              icon={<ThunderboltOutlined />}
            >
              Brands
            </Menu.Item>
            <Menu.Item
              key="8"
              onClick={handleVendorList}
              icon={<UserOutlined />}
            >
              Vendors
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={handleStoreRequest}
              icon={<InboxOutlined />}
            >
              Store
            </Menu.Item>
          </>
        );
        break;
      case "register":
        items.push(
          <>
            <Menu.Item
              key="1"
              onClick={handleOrders}
              icon={<DatabaseOutlined />}
            >
              Orders
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={handleStoreRequest}
              icon={<InboxOutlined />}
            >
              Store
            </Menu.Item>
          </>
        );
        break;
      case "engineer":
        items.push(
          <Menu.Item key="3" onClick={handleEngineer} icon={<UserOutlined />}>
            Engineer
          </Menu.Item>,
          <Menu.Item
            key="4"
            onClick={handleStoreRequest}
            icon={<InboxOutlined />}
          >
            Store
          </Menu.Item>
        );
        break;
      case "transport":
        items.push(
          <>
            <Menu.Item key="6" onClick={handlePickUp} icon={<CarOutlined />}>
              PickUp
            </Menu.Item>
            <Menu.Item
              key="5"
              onClick={handleDelivery}
              icon={<ExportOutlined />}
            >
              Delivery
            </Menu.Item>
          </>
        );
        break;
      case "worker":
        items.push(
          <>
            <Menu.Item
              key="6"
              onClick={handleInventory}
              icon={<BankOutlined />}
            >
              Inventory
            </Menu.Item>
            <Menu.Item
              key="8"
              onClick={handleRequests}
              icon={<InboxOutlined />}
            >
              Store Requests
            </Menu.Item>
            <Menu.Item
              key="12"
              onClick={handleConfirmRequest}
              icon={<ExportOutlined />}
            >
              Confirm Requests
            </Menu.Item>
          </>
        );
        break;
      default:
        return null;
    }

    if (!screens.md) {
      items.push(
        <Menu.Item key="logout" onClick={handleLogout}>
          Logout
        </Menu.Item>
      );
    }

    return items;
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "#fff",
          padding: "0 16px",
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
          }}
          justify="space-between"
          align="middle"
        >
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Pro Display Solutions
            </Typography.Title>
          </Col>
          <Col>
            {!screens.md ? (
              <Button
                icon={<MenuOutlined />}
                onClick={() => setDrawerVisible(true)}
              />
            ) : (
              <Dropdown overlay={menu} trigger={["click"]}>
                <Avatar
                  size={35}
                  icon={<UserOutlined />}
                  style={{ cursor: "pointer" }}
                />
              </Dropdown>
            )}
          </Col>
        </Row>
      </Header>

      <Layout>
        {!screens.md ? (
          <Drawer
            title="Menu"
            placement="left"
            closable={true}
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
          >
            <Menu mode="inline" style={{ borderRight: 0 }}>
              {renderMenuItems()}
            </Menu>
          </Drawer>
        ) : (
          <Sider
            width={200}
            style={{
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Menu mode="inline" style={{ borderRight: 0 }}>
              {renderMenuItems()}
            </Menu>
          </Sider>
        )}

        <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
