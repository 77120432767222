import React, { useContext } from "react";
import { Row, Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";

import { login } from "../AxiosApis/api";
import { UserContext } from "../../UserContext";

const Login = () => {
  const navigate = useNavigate();
  const { setUserDetails, fetchUser } = useContext(UserContext);

  const defaultEmail = "superadmin@gmail.com";
  const defaultPassword = "super1234";

  const onFinish = async (values) => {
    try {
      // Check if the login attempt is for the default user
      if (
        values.email === defaultEmail &&
        values.password === defaultPassword
      ) {
        // Call the backend to generate the default token
        const response = await login({
          email: defaultEmail,
          password: defaultPassword,
        });

        if (response.data.status === "Success") {
          setUserDetails(response.data.data.user);
          localStorage.setItem("token", response.data.token);
          message.success("Logged in with default credentials!");
          await fetchUser();
          navigate("/Allorders");
        }
      } else {
        // Handle regular login via the backend
        const response = await login({
          email: values.email,
          password: values.password,
        });

        if (response.data.status === "Success") {
          message.success("Login successful!");
          setUserDetails(response.data.data.user);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.data.user)
          );
          await fetchUser();

          const userRole = response.data.data.user.role;
          if (userRole === "admin") {
            navigate("/orders");
          } else if (userRole === "register") {
            navigate("/orders");
          } else if (userRole === "engineer") {
            navigate("/engineer");
          } else if (userRole === "transport") {
            navigate("/pickup");
          } else if (userRole === "worker") {
            navigate("/requests");
          } else {
            navigate("/orders");
          }
        } else {
          message.error("Login failed. Please try again.");
        }
      }
    } catch (error) {
      message.error(
        error.response?.data?.message || "Login failed. Please try again."
      );
    }
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: 'url("/BG.svg")',
      }}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="Enter your email" style={{ width: "300px" }} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            placeholder="Enter your password"
            style={{ width: "300px" }}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default Login;
