import React, { useEffect, useState } from "react";
import { Col, Row, Table, Tag } from "antd";
import AppLayout from "../../layout/Layout";
import { getAllConfirmRequest } from "../AxiosApis/api";

const ConfirmRequest = () => {
  const [productRequests, setProductRequests] = useState([]);

  useEffect(() => {
    fetchProductRequests();
  }, []);

  const fetchProductRequests = async () => {
    try {
      const response = await getAllConfirmRequest();

      setProductRequests(response.data.data);
    } catch (error) {
      console.error("Failed to fetch product requests", error);
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Request Person",
      dataIndex: "userId",
      key: "userId",
      render: (user) => user?.name || "N/A",
    },
    {
      title: "Under SPF",
      dataIndex: "admin",
      key: "admin",
      render: (admin) => (
        <>
          <div>SPF Name: {admin?.name || "N/A"}</div>
          <div>SPF Email: {admin?.email || "N/A"}</div>
        </>
      ),
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      render: (products) =>
        products?.map((product) => (
          <div key={product.productId?._id || product._id}>
            {product.productId?.name || "Unknown Product"} - {product.quantity}
          </div>
        )) || "No products available",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "product_delivered" ? "green" : "volcano"}>
          {status}
        </Tag>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={productRequests}
            rowKey="_id"
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
    </AppLayout>
  );
};

export default ConfirmRequest;
