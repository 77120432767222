import axios from "axios";

/////////////////////////////Production////////////////////////////////
export const API_BASE_PHOTO_URL = "https://api.prodisplaysolution.com";
export const API_BASE_URL = "https://api.prodisplaysolution.com/api/prodisplay";

//////////////////////LocalHost/////////////////////////////
// export const API_BASE_PHOTO_URL = "http://localhost:4500";
// export const API_BASE_URL = "http://localhost:4500/api/prodisplay";

const getToken = () => {
  return localStorage.getItem("token");
};

// Global axios instance with token injected
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/////////////////////// Login APIs ////////////////////////////////
export const login = (data) => {
  return axios.post(`${API_BASE_URL}/login`, data);
};
export const signup = (data) => {
  return axios.post(`${API_BASE_URL}/signup`, data);
};
export const logout = () => {
  return axiosInstance.post(`/logout`);
};
export const getLoggedInUser = () => {
  return axiosInstance.get(`/getloginuser`);
};

export const getServiceRequests = () => {
  return axios.get(`${API_BASE_URL}/contact/get-all-servicerequests`);
};
export const editServiceRequests = (id, data) => {
  return axios.patch(`${API_BASE_URL}/contact/editservicerequest/${id}`, data);
};
export const deleteServiceRequests = (id) => {
  return axios.delete(`${API_BASE_URL}/contact/delete-servicerequest/${id}`);
};

/////////////////////// Order APIs /////////////////////////////////
export const createOrder = (data) => {
  return axiosInstance.post("/orders/create-order", data);
};

export const getAllOrders = ({ email }) => {
  return axiosInstance.get(`/orders/list-orders`, {
    params: {
      email,
    },
  });
};

// Fetch admins based on email or name (one or the other should be provided)
export const searchAdmins = (params) => {
  return axiosInstance.get(`/orders/search-admins`, {
    params,
  });
};

export const getOrdersUnderAdmin = ({ adminId }) => {
  return axiosInstance.get(`/orders/orders-under-admin`, {
    params: {
      adminId,
    },
  });
};

export const getQrCode = (id) => {
  return axiosInstance.get(`/orders/${id}/qrcode`);
};
export const searchOrder = (params) => {
  return axiosInstance.get(`/orders/order/search`, {
    params: {
      ...params,
      email: params.email,
    },
  });
};
export const updateOrder = (id, data) => {
  return axiosInstance.put(`/orders/update/${id}`, data);
};
export const deleteOrder = (id) => {
  return axiosInstance.delete(`/orders/delete/${id}`);
};
export const deletePhoto = (orderId, photoType, photoPath) => {
  return axiosInstance.delete(
    `/orders/delete-image/${orderId}/photos/${photoType}/${encodeURIComponent(
      photoPath
    )}`
  );
};

export const estiApproved = (data) => {
  return axiosInstance.post("/orders/estiApproved", data);
};
export const estiRejected = (data) => {
  return axiosInstance.post("/orders/estiRejected", data);
};
export const orderAtStore = (data) => {
  return axiosInstance.post(`/orders/order-at-store`, data);
};
export const cancelOrderAtStore = (data) => {
  return axiosInstance.post(`/orders/cancel-order-at-store`, data);
};
export const ccoResolved = (data) => {
  return axiosInstance.post(`/orders/cco-resolved`, data);
};
export const cancelCcoResolved = (data) => {
  return axiosInstance.post(`/orders/cancel-cco-resolved`, data);
};
export const ccoDelivered = (data) => {
  return axiosInstance.post(`/orders/cco-delivered`, data);
};
export const cancelCcoDelivered = (data) => {
  return axiosInstance.post(`/orders/cancel-cco-delivered`, data);
};
export const assignPickup = (data) => {
  return axiosInstance.post(`/orders/assign-pickup`, data);
};
export const cancelAssignPick = (data) => {
  return axiosInstance.put(`/orders/cancel-assign-pickup`, data);
};
export const markAsPickedUp = (data) => {
  return axiosInstance.put(`/orders/mark-picked-up`, data);
};
export const markAsAtStore = (data) => {
  return axiosInstance.put(`/orders/mark-at-store`, data);
};
export const assignEngineer = (data) => {
  return axiosInstance.post(`/orders/assign-engineer`, data);
};
export const cancelAssignEngineer = (data) => {
  return axiosInstance.post(`/orders/cancel-assign-engineer`, data);
};
export const markAsResolved = (data) => {
  return axiosInstance.post(`/orders/marked-resolved`, data);
};
export const storePickup = (data) => {
  return axiosInstance.post(`/orders/store-pickup`, data);
};
export const cancelStorePickup = (data) => {
  return axiosInstance.post(`/orders/cancel-store-pickup`, data);
};
export const reportIssue = (data) => {
  return axiosInstance.post(`/orders/issue-reported`, data);
};
export const assignDeliveryBoy = (data) => {
  return axiosInstance.post(`/orders/assign-delivery`, data);
};
export const cancelAssignDelivery = (data) => {
  return axiosInstance.post(`/orders/cancel-assign-delivery`, data);
};
export const markAsDelivered = (data) => {
  return axiosInstance.put(`/orders/mark-delivered`, data);
};

// INVOICE
export const generateInvoice = (orderId) => {
  return axiosInstance.get(`/orders/generate-invoice/${orderId}`, {
    responseType: "blob", // For file responses
  });
};

////////////////////// Staff APIs /////////////////////////////////
export const createStaff = (data) => {
  return axiosInstance.post(`/staff/create-staff`, data);
};
export const getAllStaff = ({ email }) => {
  return axiosInstance.get(`/staff/getAll-staff`, {
    params: {
      email,
    },
  });
};
export const getAllAdmins = () => {
  return axiosInstance.get("/staff/all-admins");
};

export const searchAllAdmins = (params) => {
  return axiosInstance.get("/staff/search-admins", { params });
};
export const searchStaff = (params) => {
  return axiosInstance.get(`/staff/search-staff`, {
    params: {
      ...params,
      email: params.email,
    },
  });
};
export const updateStaff = (id, data) => {
  return axiosInstance.put(`/staff/update-staff/${id}`, data);
};
export const deleteStaff = (id) => {
  return axiosInstance.delete(`/staff/delete-staff/${id}`);
};
export const getEngineerOrders = (engineerId) => {
  return axiosInstance.get(`/staff/engineer/${engineerId}`);
};
export const getProductRequests = (userId, adminEmail) => {
  return axiosInstance.get(`/staff/store-requests/${userId}`, {
    params: {
      email: adminEmail,
    },
  });
};

export const getPickupOrders = (transportPersonId) => {
  return axiosInstance.get(`/staff/pickup/${transportPersonId}`);
};
export const getDeliveryOrders = (transportPersonId) => {
  return axiosInstance.get(`/staff/delivery/${transportPersonId}`);
};

////////////////////// Inventory APIs /////////////////////////////
// Category APIs
export const getAllCategories = () => {
  return axiosInstance.get(`/inventory/getAllCategory`);
};
export const searchCategory = (params) => {
  return axiosInstance.get(`/inventory/search-categories`, {
    params: {
      ...params,
    },
  });
};
export const createCategory = (data) => {
  return axiosInstance.post(`/inventory/create-category`, data);
};
export const updateCategory = (id, data) => {
  return axiosInstance.put(`/inventory/update-category/${id}`, data);
};
export const deleteCategory = (id) => {
  return axiosInstance.delete(`/inventory/delete-category/${id}`);
};

// SubCategory APIs
export const getAllSubCategories = () => {
  return axiosInstance.get(`/inventory/getAllSubCategory`);
};
export const searchSubCategory = (params) => {
  return axiosInstance.get(`/inventory/search-subcategories`, {
    params: {
      ...params,
    },
  });
};
export const createSubCategory = (data) => {
  return axiosInstance.post(`/inventory/create-sub-category`, data);
};
export const updateSubCategory = (id, data) => {
  return axiosInstance.put(`/inventory/update-sub-category/${id}`, data);
};
export const deleteSubCategory = (id) => {
  return axiosInstance.delete(`/inventory/delete-sub-category/${id}`);
};

// Product APIs
export const getAllProducts = () => {
  return axiosInstance.get(`/inventory/getAllProducts`);
};
export const searchProduct = (params) => {
  return axiosInstance.get(`/inventory/search-products`, {
    params: {
      ...params,
    },
  });
};
export const createProduct = (data) => {
  return axiosInstance.post(`/inventory/create-product`, data);
};
export const updateProduct = (id, data) => {
  return axiosInstance.put(`/inventory/update-product/${id}`, data);
};
export const deleteProduct = (id) => {
  return axiosInstance.delete(`/inventory/delete-product/${id}`);
};

////////////////////// Product Request APIs ////////////////////////
export const requestProduct = (data) => {
  return axiosInstance.post(`/orders/store/request-product`, data);
};
export const getAllProductRequest = () => {
  return axiosInstance.get(`/orders/store/get-all-requests`);
};
export const editProductRequest = (id, data) => {
  return axiosInstance.patch(`/orders/store/edit-product-request/${id}`, data);
};
export const deleteRequestProduct = (id) => {
  return axiosInstance.delete(`/orders/store/delete-product-request/${id}`);
};

// Products Confirm APIs
export const confirmProductRequest = (data) => {
  return axiosInstance.post(`/inventory/confirm-product-delivery`, data);
};
export const getAllConfirmRequest = () => {
  return axiosInstance.get(`/inventory/get-all-confirm-delivery`);
};

//Brands List
export const createProductList = (data) => {
  return axiosInstance.post(`/product-item/create`, data);
};
export const getAllProductList = ({ email }) => {
  return axiosInstance.get("/product-item/getAllProductItem", {
    params: {
      email,
    },
  });
};
export const searchProductList = (params) => {
  return axiosInstance.get(`/product-item/search`, {
    params: {
      ...params,
      email: params.email,
    },
  });
};
export const editProductList = (id, data) => {
  return axiosInstance.put(`/product-item/edit/${id}`, data);
};
export const deleteProductList = (id) => {
  return axiosInstance.delete(`/product-item/delete/${id}`);
};

// Vendor List
export const createVendorList = (data) => {
  return axiosInstance.post("/vendor/create-vendor", data);
};
export const getAllVendorList = ({ email }) => {
  return axiosInstance.get("/vendor/getAllVendors", {
    params: {
      email,
    },
  });
};
export const searchVendorList = (params) => {
  return axiosInstance.get(`/vendor/search-vendor`, {
    params: {
      ...params,
      email: params.email,
    },
  });
};
export const editVendorList = (id, data) => {
  return axiosInstance.put(`/vendor/editvendor/${id}`, data);
};
export const deleteVendorList = (id) => {
  return axiosInstance.delete(`/vendor/delete-vendor/${id}`);
};
