import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { createProductList, editProductList } from "./../AxiosApis/api";

const ProductListForm = ({ category, onSuccess, setIsModalOpen }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (category) {
      form.setFieldsValue(category); // Set form values for editing
    } else {
      form.resetFields(); // Reset the form when creating a new category
    }
  }, [category, form]);

  const onFinish = async (values) => {
    try {
      if (category) {
        // Update category
        await editProductList(category._id, values);
        message.success("Category updated successfully");
      } else {
        // Create category
        await createProductList(values);
        form.resetFields();
        message.success("Category created successfully");
      }
      onSuccess(); // Fetch categories again after success
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      message.error("Failed to save category");
      console.error("Error:", error);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Brand Name"
        rules={[{ required: true, message: "Please enter the Brand name" }]}
      >
        <Input placeholder="Enter the Brand name" />
      </Form.Item>

      <Row>
        <Col span={24} style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {category ? "Update Brand" : "Add Brand"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductListForm;
