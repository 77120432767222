import React, { createContext, useEffect, useState } from "react";
import { getLoggedInUser } from "./components/AxiosApis/api";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userDetails) {
      fetchUser();
    }
  }, [userDetails]);

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("No token found. Please log in again.");
      }

      const response = await getLoggedInUser(token);
      setUserDetails(response?.data?.data?.user);
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{ userDetails, loading, setUserDetails, fetchUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
