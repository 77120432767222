import React from "react";
import { Button, Form, Input, message, Select, Row, Col } from "antd";
import { createStaff } from "../AxiosApis/api";

const CreateStaff = ({ staffList, setStaffList, setStaffModal }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const response = await createStaff(values);
      if (response.data.success) {
        message.success("Staff added successfully!");
        setStaffList([...staffList, response?.data?.data]);
        setStaffModal(false);
        form.resetFields();
      }
    } catch (error) {
      message.error(error.response.data.message || "Failed to add staff.");
    }
  };

  return (
    <Row>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please Enter your name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[{ required: true, message: "Please enter your number!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="personalemail" label="Personal Email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="aadhar" label="Aadhar Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="email"
              label="Create Office Email"
              rules={[
                {
                  required: true,
                  message: "Please enter your Office email!",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              label="Create Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
                {
                  pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
                  message:
                    "Password must be at least 8 characters, Include one Uppercase letter, one Lowercase Letter, and One Number.",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: "Please Select the Role" }]}
            >
              <Select>
                <Select.Option value="register">CCO</Select.Option>
                <Select.Option value="engineer">Engineer</Select.Option>
                <Select.Option value="transport">Transport</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name={["address", "fulladdress"]}
              label="Full Address"
              rules={[
                { required: true, message: "Please Enter the Full Address" },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Staff
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default CreateStaff;
