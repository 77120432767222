import React, { useEffect, useState } from "react";
import {
  deleteProduct,
  getAllProducts,
  getAllSubCategories,
  searchProduct,
} from "./../AxiosApis/api";
import Inventory from "./Inventory";
import {
  Button,
  Table,
  Modal,
  Row,
  Col,
  Input,
  AutoComplete,
  Space,
  Popconfirm,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import ProductForm from "./ProductForm";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  const showModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const fetchProducts = async () => {
    try {
      const res = await getAllProducts();
      const sortedCategories = res.data.allProducts.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setProducts(sortedCategories);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchSubCategories = async () => {
    const res = await getAllSubCategories();
    setSubCategories(res.data.allSubCategory);
  };

  const getProductsWithSubCategoryAndCategoryNames = () => {
    return products.map((product) => ({
      ...product,
      subCategoryName: product.subCategory?.name || "Unknown",
      categoryName: product.subCategory?.category?.name || "Unknown",
    }));
  };

  const handleDelete = async (id) => {
    try {
      await deleteProduct(id);
      message.success("Product deleted successfully");
      fetchProducts();
    } catch (error) {
      message.error("Failed to delete product");
    }
  };

  const handleSearch = async (value) => {
    setSearchText(value);
    if (value) {
      try {
        const response = await searchProduct({
          name: value,
        });
        const options = response.data.products.map((product) => ({
          value: product.name,
        }));
        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
      fetchProducts();
    }
  };

  const handleSelect = async (value) => {
    try {
      const response = await searchProduct({
        name: value,
      });
      setProducts(response.data.products);
    } catch (error) {
      console.error("Error fetching selected products:", error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
    fetchProducts();
  }, []);

  return (
    <Inventory>
      <h2>Products</h2>
      <Row gutter={[12, 20]}>
        <Col span={8}>
          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by name"
              allowClear
            />
          </AutoComplete>
        </Col>

        <Col span={8} offset={8} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => showModal(null)}>
            Create Product <PlusCircleFilled />
          </Button>
        </Col>

        <Col span={24}>
          <Table
            dataSource={getProductsWithSubCategoryAndCategoryNames()}
            rowKey="_id"
            scroll={{ x: "max-content" }}
            columns={[
              {
                title: "Category",
                dataIndex: "categoryName",
              },
              {
                title: "SubCategory",
                dataIndex: "subCategoryName",
              },
              { title: "Product Name", dataIndex: "name" },
              { title: "Quantity", dataIndex: "quantity" },

              {
                title: "DAP",
                dataIndex: "price",
                key: "price",
                render: (price) => `${price.DAP}`,
              },
              {
                title: "MRP",
                dataIndex: "price",
                key: "price",
                render: (price) => `${price.MRP}`,
              },
              {
                title: "Actions",
                key: "actions",
                render: (product) => (
                  <Space>
                    <EditOutlined
                      style={{ color: "#1890ff", marginRight: 12 }}
                      onClick={() => showModal(product)}
                    />
                    <Popconfirm
                      title="Are you sure to delete this Product?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleDelete(product._id)}
                    >
                      <DeleteOutlined style={{ color: "#ff4d4f" }} />
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Modal
        title={selectedProduct ? "Edit Product" : "Create Product"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <ProductForm
          product={selectedProduct}
          subCategories={subCategories}
          onSuccess={() => fetchProducts()}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </Inventory>
  );
};

export default Products;
