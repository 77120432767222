import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../components/login/login";
import Order from "../components/orders/orders";
import Staff from "../components/staff/Staff";
import Signup from "../components/login/signUp";
import Engineer from "../components/engineer/Engineer";
import Inventory from "./../components/inventoryStore/Inventory";
import Delivery from "./../components/delivery/Delivery";
import PickUp from "./../components/pickup/PickUp";
import Category from "./../components/inventoryStore/Category";
import Subcategory from "./../components/inventoryStore/SubCategories";
import Products from "./../components/inventoryStore/Product";
import StoreRequest from "../components/Store/StoreRequest";
import ProductList from "../components/productList/ProductList";
import Vendor from "../components/vendor/Vendor";
import SuperStaff from "../components/SuperAdmin/SuperStaff";
import SuperOrder from "../components/SuperAdmin/SuperOrder";
import Requests from "../components/Store/Requests";
import ConfirmRequest from "../components/Store/ConfirmRequest";
import ServiceRequests from "../components/SuperAdmin/ServiceRequests";

const ProjectRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/signup" element={<Signup />} />

        <Route path="/orders" element={<Order />} />

        <Route path="/staff" element={<Staff />} />

        <Route path="/engineer" element={<Engineer />} />

        <Route path="/delivery" element={<Delivery />} />

        <Route path="/pickup" element={<PickUp />} />

        <Route path="/inventory" element={<Inventory />} />

        <Route path="/categories" element={<Category />} />

        <Route path="/subcategories" element={<Subcategory />} />

        <Route path="/products" element={<Products />} />

        <Route path="/storerequest" element={<StoreRequest />} />

        <Route path="/requests" element={<Requests />} />

        <Route path="/confirmRequest" element={<ConfirmRequest />} />

        <Route path="/brands" element={<ProductList />} />

        <Route path="/vendors" element={<Vendor />} />

        <Route path="/AllStaff" element={<SuperStaff />} />

        <Route path="/Allorders" element={<SuperOrder />} />

        <Route path="/serviceRequests" element={<ServiceRequests />} />
      </Routes>
    </BrowserRouter>
  );
};

export default ProjectRoutes;
