import { React } from "react";
import AppLayout from "../../layout/Layout";

import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const Inventory = ({ children }) => (
  <AppLayout>
    <Layout>
      <Header>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item key="1">
            <Link to="/categories">Categories</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/subcategories">Subcategories</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/products">Products</Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <div style={{ margin: "16px 0" }}>{children}</div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Inventory Management ©2024
      </Footer>
    </Layout>
  </AppLayout>
);

export default Inventory;
