import { useState } from "react";
import { Modal, Image, Button } from "antd";
import {
  EyeOutlined,
  DownloadOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

const PhotoGallery = ({ photos, orderId, API_BASE_PHOTO_URL }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");

  const showPhotoModal = (photo) => {
    setSelectedPhoto(photo);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedPhoto("");
  };

  const handleDownload = (photo) => {
    const fileUrl = `${API_BASE_PHOTO_URL}/${photo}`;
    window.open(fileUrl, "_blank");
  };

  const isPdf = (fileName) => {
    return fileName.endsWith(".pdf");
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {photos.map((photo, index) => {
        const isPDF = isPdf(photo);
        return (
          <div key={index} style={{ position: "relative" }}>
            {isPDF ? (
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => showPhotoModal(photo)}
              >
                <FilePdfOutlined
                  style={{ fontSize: "40px", color: "#ff4d4f" }}
                />
                <div style={{ fontSize: "12px", color: "#888" }}>PDF</div>
              </div>
            ) : (
              <img
                src={`${API_BASE_PHOTO_URL}/${photo}`}
                alt={`Order ${orderId}photo ${index + 1}`}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => showPhotoModal(photo)}
              />
            )}

            <EyeOutlined
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                fontSize: "18px",
                color: "#fff",
                background: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
              }}
              onClick={() => showPhotoModal(photo)}
            />
          </div>
        );
      })}

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        centered
        width="80%"
        destroyOnClose={true}
      >
        {!isPdf(selectedPhoto) ? (
          <Image
            src={`${API_BASE_PHOTO_URL}/${selectedPhoto}`}
            alt="Enlarged photo"
            style={{ width: "100%" }}
          />
        ) : (
          <div style={{ textAlign: "center" }}>
            <embed
              src={`${API_BASE_PHOTO_URL}/${selectedPhoto}`}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Button
            type="default"
            onClick={handleCancel}
            style={{
              marginTop: "10px",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              borderColor: "#d9d9d9",
              color: "#333",
            }}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(selectedPhoto)}
            style={{
              marginTop: "10px",
              borderRadius: "4px",
            }}
          >
            Download
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PhotoGallery;
