import React, { useState } from "react";
import AppLayout from "./../../layout/Layout";
import {
  Col,
  Row,
  Table,
  Modal,
  Space,
  Radio,
  AutoComplete,
  Input,
  Tag,
} from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  getOrdersUnderAdmin,
  searchAdmins,
  searchOrder,
} from "../AxiosApis/api";
import ViewOrder from "../orders/ViewOrder";

const SuperOrder = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [searchType, setSearchType] = useState("name");
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewOrderModalVisible, setViewOrderModalVisible] = useState(false);
  const [adminId, setAdminId] = useState(null); // Store the selected adminId
  const [orderSearchText, setOrderSearchText] = useState(""); // Search text for filtering orders
  const [statusFilter, setStatusFilter] = useState(null); // Status filter for orders

  // Handle admin search
  const handleSearch = async (value) => {
    setSearchText(value);
    if (value) {
      try {
        const response = await searchAdmins({ [searchType]: value });
        const options = response.data.data.admins.map((admin) => ({
          value: searchType === "name" ? admin.name : admin.email,
          adminId: admin._id, // Store adminId in the option
        }));
        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
    }
  };

  // Handle admin selection
  const handleSelect = (value, option) => {
    const selectedAdminId = option.adminId;
    setAdminId(selectedAdminId); // Save adminId to state
    fetchOrders(selectedAdminId); // Fetch orders for the selected admin
  };

  // Fetch orders for selected admin
  const fetchOrders = async (adminId) => {
    setLoading(true);
    try {
      const response = await getOrdersUnderAdmin({
        adminId,
        status: statusFilter,
      }); // Add status filter
      const sortedOrders = response.data.data.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrders(sortedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle order search
  const handleOrderSearch = async (value) => {
    setOrderSearchText(value); // Update order search text
    if (adminId && value) {
      try {
        const response = await searchOrder({ adminId, name: value });
        setOrders(response.data.data.orders); // Update filtered orders
      } catch (error) {
        console.error("Error searching for orders:", error);
      }
    }
  };

  // Clear search and reset the table
  const handleClear = () => {
    setOrders([]); // Clear the orders table
    setSearchText(""); // Reset the search input
    setSearchOptions([]); // Reset search suggestions
    setOrderSearchText(""); // Reset order search input
  };

  // Handle status filter
  const handleTableChange = (pagination, filters, sorter) => {
    const selectedStatus = filters.status ? filters.status[0] : null;
    setStatusFilter(selectedStatus); // Set the status filter state
    if (adminId) {
      fetchOrders(adminId); // Re-fetch orders based on the selected status
    }
  };

  // View order details in a modal
  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setViewOrderModalVisible(true);
  };

  // Status color for each order status
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "orange";
      case "assign_pick_up":
        return "geekblue";
      case "picked_up":
        return "green";
      case "order_At_Store":
        return "volcano";
      case "in_progress":
        return "purple";
      case "issue_reported":
        return "pink";
      case "resolved":
        return "cyan";
      case "assign_delivery":
        return "gold";
      case "delivered":
        return "green";
      default:
        return "blue";
    }
  };

  // Table columns definition with status filtering
  const columns = [
    { title: "Name", dataIndex: "name", key: "name", width: 90 },
    { title: "Order ID", dataIndex: "orderId", key: "orderId", width: 50 },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 50,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 90,
    },
    {
      title: "Product Model",
      dataIndex: "productModel",
      key: "productModel",
      width: 90,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Assign Pick Up", value: "assign_pick_up" },
        { text: "Picked Up", value: "picked_up" },
        { text: "Order At Store", value: "order_At_Store" },
        { text: "In Progress", value: "in_progress" },
        { text: "Issue Reported", value: "issue_reported" },
        { text: "Resolved", value: "resolved" },
        { text: "Assign Delivery", value: "assign_delivery" },
        { text: "Delivered", value: "delivered" },
      ],
      onFilter: (value, record) => record.status === value, // Filter orders by status
      render: (status) => (
        <Tag color={getStatusColor(status)}>{status.toUpperCase()}</Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 50,
      render: (text, record) => (
        <Space size="middle">
          <EyeOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleViewOrder(record)}
          />
        </Space>
      ),
    },
  ];
  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col span={8}>
          {/* Admin Search Input */}
          <Radio.Group
            onChange={(e) => setSearchType(e.target.value)}
            value={searchType}
            style={{ marginBottom: "16px" }}
          >
            <Radio.Button value="email">Email</Radio.Button>
            <Radio.Button value="name">Name</Radio.Button>
          </Radio.Group>

          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
            onClear={handleClear}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by Name or Email"
              allowClear
            />
          </AutoComplete>
        </Col>

        <Col span={24}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={orders}
            rowKey="_id"
            scroll={{ x: "max-content" }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>

      <Modal
        title="Order Details"
        visible={viewOrderModalVisible}
        onCancel={() => setViewOrderModalVisible(false)}
        footer={null}
      >
        <ViewOrder
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          getStatusColor={getStatusColor}
        />
      </Modal>
    </AppLayout>
  );
};

export default SuperOrder;
