import React, { useEffect } from "react";
import { Form, Input, Button, Select, Row, Col, message } from "antd";
import { createSubCategory, updateSubCategory } from "./../AxiosApis/api";

const { Option } = Select;

const SubCategoryForm = ({
  subCategory,
  categories,
  onSuccess,
  setIsModalOpen,
}) => {
  const [form] = Form.useForm();

  // Reset form values when subCategory changes
  useEffect(() => {
    form.resetFields(); // Reset fields to avoid stale data
    if (subCategory) {
      form.setFieldsValue(subCategory); // Set new initial values when subCategory changes
    }
  }, [subCategory, form]);

  const onFinish = async (values) => {
    try {
      if (subCategory) {
        await updateSubCategory(subCategory._id, values);
        message.success("Subcategory updated successfully!");
      } else {
        await createSubCategory(values);
        form.resetFields();
        message.success("Subcategory created successfully!");
      }
      setIsModalOpen(false);
      onSuccess();
    } catch (error) {
      console.error("Failed to save subcategory:", error);
      message.error("Failed to save subcategory. Please try again.");
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Subcategory Name"
        rules={[
          { required: true, message: "Please enter the subcategory name!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="category"
        label="Category"
        rules={[{ required: true, message: "Please select a category!" }]}
      >
        <Select>
          {categories.map((cat) => (
            <Option key={cat._id} value={cat._id}>
              {cat.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Row>
        <Col span={24} style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {subCategory ? "Update" : "Add"} Subcategory
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SubCategoryForm;
