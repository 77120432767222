import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { createVendorList, editVendorList } from "./../AxiosApis/api";

const VendorForm = ({ category, onSuccess, setIsModalOpen }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (category) {
      form.setFieldsValue(category);
    } else {
      form.resetFields();
    }
  }, [category, form]);

  const onFinish = async (values) => {
    try {
      if (category) {
        await editVendorList(category._id, values);
        message.success("Vendor updated successfully");
      } else {
        await createVendorList(values);
        form.resetFields();
        message.success("Vendor created successfully");
      }
      onSuccess();
      setIsModalOpen(false);
    } catch (error) {
      message.error("Failed to save Vendor");
      console.error("Error:", error);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Vendor Name"
        rules={[{ required: true, message: "Please enter the  Vendor name" }]}
      >
        <Input placeholder="Enter the Vendor name" />
      </Form.Item>

      <Form.Item name="email" label="Email">
        <Input placeholder="Enter the Email" />
      </Form.Item>

      <Form.Item name="phoneNumber" label="Phone Number">
        <Input placeholder="Enter the Phone Number" />
      </Form.Item>

      <Row>
        <Col span={24} style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {category ? "Update Vendor" : "Add Vendor"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default VendorForm;
