import React, { useEffect, useState } from "react";
import {
  getAllCategories,
  deleteCategory,
  searchCategory,
} from "./../AxiosApis/api";
import CategoryForm from "./CateforyForm";
import {
  Button,
  Table,
  Modal,
  Row,
  Col,
  Input,
  AutoComplete,
  message,
  Popconfirm,
} from "antd";
import Inventory from "./Inventory";
import {
  PlusCircleFilled,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  const showModal = (category = null) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
  };

  const fetchCategories = async () => {
    const res = await getAllCategories();
    const sortedCategories = res.data.allcategory.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setCategories(sortedCategories);
  };

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id);
      message.success("Category deleted successfully");
      fetchCategories();
    } catch (error) {
      message.error("Failed to delete category");
    }
  };

  const handleSearch = async (value) => {
    setSearchText(value);
    if (value) {
      try {
        const response = await searchCategory({
          name: value,
        });
        const options = response.data.data.map((subCat) => ({
          value: subCat.name,
        }));
        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
      fetchCategories();
    }
  };

  const handleSelect = async (value) => {
    try {
      const response = await searchCategory({
        name: value,
      });
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching selected subcategory:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Actions",
      key: "actions",
      render: (category) => (
        <span>
          <EditOutlined
            style={{ color: "#1890ff", marginRight: 12 }}
            onClick={() => showModal(category)}
          />
          <Popconfirm
            title="Are you sure to delete this Category?"
            onConfirm={() => handleDelete(category._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: "#ff4d4f" }} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Inventory>
      <h2>Categories</h2>
      <Row gutter={[12, 20]}>
        <Col span={8}>
          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by name"
              allowClear
            />
          </AutoComplete>
        </Col>

        <Col span={8} offset={8} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => showModal()}>
            Create Category <PlusCircleFilled />
          </Button>
        </Col>

        <Col span={24}>
          <Table dataSource={categories} rowKey="_id" columns={columns} />
        </Col>
      </Row>

      <Modal
        title={selectedCategory ? "Edit Category" : "Create Category"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <CategoryForm
          category={selectedCategory}
          onSuccess={() => fetchCategories()}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </Inventory>
  );
};

export default Category;
